<template>
  <div>
    <b-row v-if="canEdit">
      <b-col class="mt-2">
        <b-card-text>
          Selected items for status messages.
        </b-card-text>
      </b-col>
      <b-col class="text-right">
        <b-button
          v-if="type === 'arrived_to_facility'"
          variant="outline-success"
          class="m-1"
          :href="excelDownloadUrl"
          :disabled="!done"
        >
          Download arrived as Excel
        </b-button>
        <b-button
          variant="outline-success"
          class="m-1"
          :disabled="disabledBtn"
          @click="sendMessages"
        >
          Send status messages
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <parcels-tree
          :containers="containers"
          :shipment="shipment"
          :data-loading="loading"
          :data-loading-message="loadingMessage"
          :filter="type"
          :not-allowed-filters="['arrived']"
          @selection="selection"
          @filterTree="handleTreeFilters"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BButton,
  BCol,
  BRow,
  BCardText,
} from 'bootstrap-vue'
import ParcelsTree from '@/views/shipments/declarations/ParcelsTree.vue'
import shipmentModel from '@/views/shipments/shipmentModel'
import handleError from '@/views/components/errorHandler'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { searchQuery } from '@core/utils/filter'

export default {
  components: {
    BButton,
    BCol,
    BRow,
    BCardText,
    ParcelsTree,
  },
  props: ['eventHub', 'shipmentId', 'type', 'done'],
  data() {
    return {
      loading: false,
      loadingMessage: 'Loading',
      shipment: {},
      containers: [],
      selectedAll: false,
      selectedContainers: [],
      selectedParcels: [],
      filters: {},
    }
  },
  computed: {
    excelDownloadUrl() {
      if (this.type === 'arrived_to_facility') {
        return `${process.env.VUE_APP_API_URL}/v1/shipments/${this.shipmentId}/parcels/arrivedInFacility/excel`
      }
      return ''
    },
    canEdit() {
      return this.$permissions().hasPermission('edit shipment')
    },
    disabledBtn() {
      return (
        this.selectedAll === false
        && this.selectedContainers.length === 0
        && this.selectedParcels.length === 0
      )
        || this.containers.length === 0
        || this.loadingMessage !== 'Loading'
    },
  },
  async created() {
    await this.getShipment()
    if (this.loadingMessage === 'Loading') {
      this.fetchData()
    }
    this.eventHub.$on('general-shipment-update', this.fetchData)
    this.eventHub.$on('shipment-event-processing', this.eventsProcessing)
    this.eventHub.$on('shipment-event-finished', this.processingFinished)
  },
  beforeDestroy() {
    this.eventHub.$off('general-shipment-update', this.fetchData)
    this.eventHub.$off('shipment-event-processing', this.eventsProcessing)
    this.eventHub.$off('shipment-event-finished', this.processingFinished)
  },
  methods: {
    handleTreeFilters(filters) {
      this.filters = filters
      this.fetchData()
    },
    eventsProcessing() {
      this.loading = true
      this.loadingMessage = 'Events dispatching'
    },
    processingFinished() {
      this.loading = false
      this.loadingMessage = 'Loading'
      this.fetchData()
    },
    getShipment() {
      return new Promise(resolve => {
        const cachedData = shipmentModel().getCachedShipment(this.shipmentId)
        if (cachedData === null) {
          shipmentModel()
            .getShipment(this.shipmentId, this)
            .then(response => {
              shipmentModel().cacheShipment(response.data)
              this.$set(this, 'shipment', response.data)
              resolve() // Resolve after shipment is fetched
            })
        } else {
          this.$set(this, 'shipment', cachedData)
          resolve() // Resolve immediately if data is cached
        }
      })
    },
    fetchData(activeTab) {
      if (!activeTab || activeTab === this.type) {
        this.loading = true
        this.$http.get(searchQuery(`/v1/api/companies/${this.shipment.company_id}/shipments/${this.shipmentId}/events/${this.type}`, this.filters))
          .then(response => {
            this.containers = response.data.data
            this.loading = false
          })
          .catch(error => {
            handleError(error, this.$toast)
          })
      }
    },
    selection(data) {
      this.selectedAll = data.selectedAll
      this.selectedContainers = data.selectedContainers
      this.selectedParcels = data.selectedParcels
    },
    sendMessages() {
      this.$http.post(`/v1/api/companies/${this.shipment.company_id}/shipments/${this.shipmentId}/events`, {
        all: this.selectedAll,
        containers: this.selectedContainers,
        parcels: this.selectedParcels,
        events: [this.type],
      })
        .then(response => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: 'CheckmarkIcon',
              variant: 'success',
            },
          })
          this.fetchData()
          this.eventHub.$emit('refresh-shipment-statistics')
        })
        .catch(error => {
          handleError(error, this.$toast)
        })
    },
  },
}
</script>

<style lang="scss">

</style>
