<template>
  <b-row>
    <b-col>
      <b-card title="Filters">
        <b-form>
          <b-row>
            <b-col
              lg="4"
              mdv="4"
            >
              <b-form-group
                id="groupBy"
                label="Group by"
                label-for="grouping"
              >
                <v-select
                  v-model="filters.grouping"
                  multiple
                  :options="groupingOptionsList"
                  input-id="grouping"
                />
              </b-form-group>
            </b-col>
            <b-col
              lg="3"
              mdv="3"
            >
              <b-form-group
                label="Date range"
                label-for="date-range"
              >
                <div class="d-flex align-items-center">
                  <flat-pickr
                    id="date-range"
                    v-model="filters.dateRange"
                    class="form-control"
                    :config="datepickerConfig"
                  />
                  <feather-icon
                    v-b-tooltip.hover.right="tooltipMessage"
                    icon="AlertCircleIcon"
                    class="ml-1"
                    size="1.5x"
                  />
                </div>
              </b-form-group>

            </b-col>
            <!-- Company name filter -->
            <b-col v-if="suggestionList.length > 1">
              <b-form-group
                id="company"
                label="Company"
                label-for="company"
              >
                <v-select
                  v-model="companyIds"
                  :reduce="option => option.value"
                  label="label"
                  multiple
                  :options="suggestionList"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-button
                type="button"
                variant="primary"
                style="margin-top: 20px"
                @click="fetchData"
              >
                Filter
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-card>

      <b-card>
        <b-card-actions
          ref="loadingCard"
          no-actions
        >
          <b-table
            :items="tableDataStore"
            responsive
            :fields="visibleFields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="isSortDirDesc"
            :busy="loading"
            class="mb-0"
            show-empty
          >
            <template #table-busy>
              <div class="text-center my-2">
                <b-spinner class="align-middle mr-1" />
              </div>
            </template>
            <template v-slot:bottom-row>
              <b-td class="left">
                <strong>Total</strong>
              </b-td>
              <b-td
                v-for="field in totals.emptyFields"
                :key="field"
                class="text-right"
              >
                <strong />
              </b-td>
              <b-td class="text-right">
                <strong>{{ totals.parcels | formatNumber }}</strong>
              </b-td>
              <b-td class="text-right">
                <strong>{{ totals.parcel_items | formatNumber }}</strong>
              </b-td>
              <b-td class="text-right">
                <strong>{{ totals.parcel_items_weight | formatNumber(3) }}</strong>
              </b-td>
              <b-td class="text-right">
                <strong>{{ totals.arrived_parcels | formatNumber }}</strong>
              </b-td>
              <b-td class="text-right">
                <strong>{{ totals.arrived_parcel_items | formatNumber }}</strong>
              </b-td>
              <b-td class="text-right">
                <strong>{{ totals.arrived_parcel_items_weight | formatNumber(3) }}</strong>
              </b-td>
              <b-td class="text-right">
                <strong>{{ totals.submitted_declarations | formatNumber }}</strong>
              </b-td>
              <b-td class="text-right">
                <strong>{{ totals.submitted_declarations_weight | formatNumber(3) }}</strong>
              </b-td>
              <b-td class="text-right">
                <strong>{{ totals.cleared_declarations | formatNumber }}</strong>
              </b-td>
              <b-td
                v-if="totals.exported_parcels"
                class="text-right"
              >
                <strong>{{ totals.exported_parcels | formatNumber }}</strong>
              </b-td>
              <b-td
                v-if="totals.ens_declarations_parcels"
                class="text-right"
              >
                <strong>{{ totals.ens_declarations_parcels | formatNumber }}</strong>
              </b-td>
              <b-td
                v-if="totals.ens_declarations_items"
                class="text-right"
              >
                <strong>{{ totals.ens_declarations_items | formatNumber }}</strong>
              </b-td>
              <b-td
                v-if="totals.ens_declarations_weight"
                class="text-right"
              >
                <strong>{{ totals.ens_declarations_weight | formatNumber }}</strong>
              </b-td>
            </template>
          </b-table>
        </b-card-actions>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard,
  BRow,
  BButton,
  BCol,
  BTable,
  BFormGroup,
  BForm,
  BTd,
  BSpinner, VBTooltip,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import moment from 'moment'
import vSelect from 'vue-select'
import Vue from 'vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import { getSelectedCompanies, parseFilters, updateSelectedCompanies } from '@core/utils/filter'
import numberFormat from '@/libs/number-format'

export default {
  components: {
    flatPickr,
    BCard,
    BCardActions,
    BRow,
    BCol,
    BTable,
    BFormGroup,
    BForm,
    BButton,
    vSelect,
    BTd,
    BSpinner,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      loading: false,
      fields: [
        {
          key: 'parcels',
          label: 'Created parcels',
          sortable: true,
          thClass: 'text-right',
          tdClass: 'text-right',
          formatter: this.formatNumber,
        },
        {
          key: 'parcel_items',
          label: 'Created Items',
          sortable: true,
          thClass: 'text-right',
          tdClass: 'text-right',
          formatter: this.formatNumber,
        },
        {
          key: 'parcel_items_weight',
          label: 'Created Weight',
          sortable: true,
          thClass: 'text-right',
          tdClass: 'text-right',
          formatter: this.formatNumber,
        },
        {
          key: 'arrived_parcels',
          label: 'Arrived parcels',
          sortable: true,
          thClass: 'text-right',
          tdClass: 'text-right',
          formatter: this.formatNumber,
        },
        {
          key: 'arrived_parcel_items',
          label: 'Arrived Items',
          sortable: true,
          thClass: 'text-right',
          tdClass: 'text-right',
          formatter: this.formatNumber,
        },
        {
          key: 'arrived_parcel_items_weight',
          label: 'Arrived Weight',
          sortable: true,
          thClass: 'text-right',
          tdClass: 'text-right',
          formatter: this.formatNumber,
        },
        {
          key: 'submitted_declarations',
          label: 'Submitted H7',
          sortable: true,
          thClass: 'text-right',
          tdClass: 'text-right',
          formatter: this.formatNumber,
        },
        {
          key: 'submitted_declarations_weight',
          label: 'Submitted Weight H7',
          sortable: true,
          thClass: 'text-right',
          tdClass: 'text-right',
          formatter: this.formatNumber,
        },
        {
          key: 'cleared_declarations',
          label: 'Cleared H7',
          sortable: true,
          thClass: 'text-right',
          tdClass: 'text-right',
          formatter: this.formatNumber,
        },
        {
          key: 'submitted_declarations_h1',
          label: 'Submitted H1',
          sortable: true,
          thClass: 'text-right',
          tdClass: 'text-right',
          formatter: this.formatNumber,
        },
        {
          key: 'submitted_declarations_weight_h1',
          label: 'Submitted Weight H1',
          sortable: true,
          thClass: 'text-right',
          tdClass: 'text-right',
          formatter: this.formatNumber,
        },
        {
          key: 'cleared_declarations_h1',
          label: 'Cleared H1',
          sortable: true,
          thClass: 'text-right',
          tdClass: 'text-right',
          formatter: this.formatNumber,
        },
        {
          key: 'exported_parcels',
          label: 'Exported parcels',
          sortable: true,
          thClass: 'text-right',
          tdClass: 'text-right',
          formatter: this.formatNumber,
          // hide when no data to show
        },
        {
          key: 'ens_declarations_parcels',
          label: 'ENS parcels',
          sortable: true,
          thClass: 'text-right',
          tdClass: 'text-right',
          formatter: this.formatNumber,
          // hide when no data to show
        },
        {
          key: 'ens_declarations_items',
          label: 'ENS items',
          sortable: true,
          thClass: 'text-right',
          tdClass: 'text-right',
          formatter: this.formatNumber,
          // hide when no data to show
        },
        {
          key: 'ens_declarations_weight',
          label: 'ENS weight',
          sortable: true,
          thClass: 'text-right',
          tdClass: 'text-right',
          formatter: this.formatNumber,
          // hide when no data to show
        },
      ],
      eventHub: new Vue(),
      tableDataStore: [],
      sortBy: 'grouping',
      isSortDirDesc: true,
      totals: {
        emptyFields: [],
      },
      filters: {
        grouping: [{ value: 'shipment', label: 'Shipment' }],
        dateRange: '',
      },
      datepickerConfig: {
        mode: 'range',
        maxDate: moment().format('YYYY-MM-DD'),
      },
      latestReportDate: '',
      companyIds: getSelectedCompanies([this.$activeCompany().data.company.id]),
      companyList: [],
      groupingOptions: [
        { label: 'Shipment', value: 'shipment' },
        { label: 'Day of the year', value: 'day' },
        { label: 'Week of the month', value: 'week' },
        { label: 'Month of the year', value: 'month' },
      ],
    }
  },
  computed: {
    tooltipMessage() {
      return this.latestReportDate ? `Latest report was generated ${this.latestReportDate}` : 'Reports are generated once a day to optimize x7trade performance. No reports generated so far.'
    },
    visibleFields() {
      return this.fields.filter(field => {
        const conditionalFields = [
          'exported_parcels',
          'ens_declarations_parcels',
          'ens_declarations_items',
          'ens_declarations_weight',
          'submitted_declarations_h1',
          'submitted_declarations_weight_h1',
          'cleared_declarations_h1',
        ]
        return !conditionalFields.includes(field.key) || this.totals[field.key] > 0
      })
    },
    suggestionList() {
      const filtered = []
      this.companyList.forEach(item => {
        filtered.push({
          label: item.name,
          value: item.id,
        })
      })

      return filtered
    },
    searchQuery() {
      let url = `/v1/companies/${this.$activeCompany().data.company.id}/report/parcels`

      // Apply sorting
      url += `?sortBy=${this.$data.sortBy}`
      url += `&sortDesc=${this.$data.isSortDirDesc}`

      // Apply filters
      const filters = { ...this.$data.filters, companyIds: this.companyIds }
      const filterList = parseFilters(filters)
      const queryString = Object.keys(filterList)
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(filterList[key])}`)
        .join('&')

      url += `&${queryString}`

      updateSelectedCompanies(this.companyIds)

      return url
    },
    groupingOptionsList() {
      const list = [...this.groupingOptions]

      if (this.companyList.length > 1) {
        list.push({ label: 'Company', value: 'company' })
      }

      return list
    },
  },
  watch: {
    sortBy() {
      this.fetchData()
    },
    isSortDirDesc() {
      this.fetchData()
    },
  },
  created() {
    this.fetchData()
    this.$data.companyList = this.$profile().getUserAndAuthorizedCompanies()
  },
  methods: {
    calculateGroupingColumns() {
      // Remove grouping columns
      this.fields = this.fields.filter(item => !item.groupingColumn)

      // Create a new column for each selected grouping
      const columns = []
      this.totals.emptyFields = []
      this.filters.grouping.forEach((item, idx) => {
        columns.push({
          key: `grouping[${idx}]`,
          label: item.label,
          groupingColumn: true,
          sortable: true,
          thClass: 'text-left',
          tdClass: 'text-left',
        })
        // Skip first, one column is predefined (Total)
        if (idx === 0) return
        this.totals.emptyFields.push({
          key: `grouping[${idx}]`,
          value: item.label,
        })
      })

      // Add grouping columns to the beginning of the table
      this.fields = columns.concat(this.fields)
    },
    fetchData() {
      this.loading = true
      this.$http.get(this.searchQuery)
        .then(response => {
          this.tableDataStore = response.data.data
          this.totals = response.data.meta.totals
          this.latestReportDate = response.data.meta.latest_report_date
          if (this.latestReportDate) {
            this.filters.dateRange = `${response.data.meta.filters.dateFrom} to ${response.data.meta.filters.dateTo}`
            this.datepickerConfig.maxDate = this.latestReportDate
          }
          this.calculateGroupingColumns()
        })
        .catch(() => {
          this.calculateGroupingColumns()
        })
        .finally(() => {
          this.loading = false
        })
    },
    formatNumber(value, key) {
      return numberFormat.format(value, ['parcel_items_weight', 'arrived_parcel_items_weight', 'submitted_declarations_weight'].includes(key) ? 3 : 0)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>
