export default {
  getJsonFromBlob(input) {
    return new Promise((resolve, reject) => {
      if (!(input instanceof Blob)) {
        reject(new TypeError('The provided input is not a Blob.'))
        return
      }

      const reader = new FileReader()
      reader.onload = () => resolve(JSON.parse(reader.result))
      reader.onerror = () => reject(reader.error)
      reader.readAsText(input)
    })
  },
  downloadFile(response, fallbackFileName = '') {
    const filename = response.headers['content-disposition']?.split('=')[1] || fallbackFileName
    const url = window.URL.createObjectURL(new Blob([response.data], { type: 'text/xml' }))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', filename)
    document.body.appendChild(link)
    link.click()
    URL.revokeObjectURL(link.href)
  },
}
