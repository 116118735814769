var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_c('confirm-modal',{attrs:{"id":"previous-document-type-confirm","v-if":_vm.confirmPreviousDocument,"title":"Are you sure you want to proceed?","confirmation":_vm.prevDocTypeConfirmText},on:{"submit":function($event){return _vm.submit()}}}),_c('b-modal',{attrs:{"id":this.$attrs.id,"title":"Declaration information","size":"lg"},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('b-button',{attrs:{"variant":"primary","disabled":!_vm.shipment.entry_country},on:{"click":function($event){return _vm.validateSend()}}},[_vm._v(" Submit declarations ")])]},proxy:true}]),model:{value:(_vm.isVisible),callback:function ($$v) {_vm.isVisible=$$v},expression:"isVisible"}},[_c('b-form',[_c('validation-observer',{ref:"formValidation"},[(!_vm.clearance)?_c('b-form-group',{attrs:{"label":"Declaration type","label-for":"arrived","label-class":"mb-1"}},[_c('validation-provider',{attrs:{"name":"Declaration type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio-group',{attrs:{"id":"arrived","options":_vm.arrivedOptions,"name":"arrived","state":errors.length > 0 ? false : null},model:{value:(_vm.hasArrived),callback:function ($$v) {_vm.hasArrived=$$v},expression:"hasArrived"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,419515009)})],1):_vm._e(),_c('b-form-group',{attrs:{"label":"Choose warehouse","label-for":"vue-select"}},[_c('validation-provider',{attrs:{"name":"Warehouse","rules":'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"warehouse","options":_vm.warehouses,"state":errors.length > 0 ? false : null},model:{value:(_vm.warehouse),callback:function ($$v) {_vm.warehouse=$$v},expression:"warehouse"}})]}}])})],1),(_vm.shipment.entry_country === 'RO')?_c('b-form-group',{attrs:{"label":"Previous document category","label-for":"previous_document_category"}},[_c('validation-provider',{attrs:{"name":"Previous document category"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"previous_document_category","options":_vm.previous_document_categories,"placeholder":"Choose document category","state":errors.length > 0 ? false : null},model:{value:(_vm.previous_document_category),callback:function ($$v) {_vm.previous_document_category=$$v},expression:"previous_document_category"}})]}}],null,false,3067563711)})],1):_vm._e(),(_vm.shipment.entry_country !== 'LT')?_c('b-form-group',{attrs:{"label":"Previous document type","label-for":"previous_document_type"}},[_c('validation-provider',{attrs:{"name":"Previous document type","rules":_vm.shipment.entry_country !== 'RO' && _vm.declarationType === 'h1' ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"previous_document_type","options":_vm.previous_document_types,"placeholder":"Choose document type","state":errors.length > 0 ? false : null},model:{value:(_vm.previous_document_type),callback:function ($$v) {_vm.previous_document_type=$$v},expression:"previous_document_type"}})]}}],null,false,3061538250)})],1):_vm._e(),(_vm.shipment.entry_country !== 'LT')?_c('b-form-group',{attrs:{"label":"Previous document reference","label-for":"previous_document_reference"}},[_c('validation-provider',{attrs:{"name":"Previous document reference","rules":_vm.shipment.entry_country !== 'RO' && _vm.declarationType === 'h1' ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"previous_document_reference","placeholder":"Previous document reference","autocomplete":"off","state":errors.length > 0 ? false : null},model:{value:(_vm.previous_document_reference),callback:function ($$v) {_vm.previous_document_reference=$$v},expression:"previous_document_reference"}})]}}],null,false,2401271175)})],1):_vm._e(),(_vm.declarationType === 'h1')?_c('b-form-group',{attrs:{"label":"Inland mode of transport","label-for":"inland_mode_of_transport"}},[_c('validation-provider',{attrs:{"name":"Inland mode of transport","rules":'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"inland_mode_of_transport","options":_vm.transport_types,"state":errors.length > 0 ? false : null},model:{value:(_vm.inland_mode_of_transport),callback:function ($$v) {_vm.inland_mode_of_transport=$$v},expression:"inland_mode_of_transport"}})]}}],null,false,1456455063)})],1):_vm._e(),(_vm.declarationType === 'h1')?_c('b-form-group',{attrs:{"label":"Mode of transport at the border","label-for":"mode_of_transport_at_border"}},[_c('validation-provider',{attrs:{"name":"Mode of transport at the border","rules":'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"mode_of_transport_at_border","options":_vm.transport_types,"state":errors.length > 0 ? false : null},model:{value:(_vm.mode_of_transport_at_border),callback:function ($$v) {_vm.mode_of_transport_at_border=$$v},expression:"mode_of_transport_at_border"}})]}}],null,false,1858754647)})],1):_vm._e(),(_vm.mode_of_transport_at_border)?_c('b-form-group',{attrs:{"label":"Transport registration number","label-for":"transport_registration_number"}},[_c('validation-provider',{attrs:{"name":"Transport registration number","rules":'max:35'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"transport_registration_number","placeholder":"Transport registration number","autocomplete":"off","state":errors.length > 0 ? false : null},model:{value:(_vm.transport_registration_number),callback:function ($$v) {_vm.transport_registration_number=$$v},expression:"transport_registration_number"}})]}}],null,false,3241260739)})],1):_vm._e(),(_vm.showNotArrivedOption && _vm.warehouse && !_vm.resendables)?_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"text-danger",attrs:{"for":"send_arrived_to_facility"}},[_vm._v(" Some of selected containers/parcels are missing \"Arrived to facility\" event ")]),_c('b-form-checkbox',{attrs:{"id":"send_arrived_to_facility","name":"send_arrived_to_facility","value":"true","unchecked-value":"false"},model:{value:(_vm.send_arrived_to_facility),callback:function ($$v) {_vm.send_arrived_to_facility=$$v},expression:"send_arrived_to_facility"}},[_vm._v(" Send \"Arrived to facility\" event ")])],1):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }