<template>
  <b-row>
    <b-col>
      <b-overlay
        :show="loading"
        rounded
        opacity="0.6"
        spinner-variant="primary"
      >
        <b-card title="Shipment">
          <floating-title-badge
            v-if="!is_export"
            :message="ensLabelMessage"
            :is-success="ensCompliant"
          />
          <validation-observer
            ref="shipmentValidation"
          >
            <b-form ref="shipmentForm">
              <b-row>
                <b-col
                  v-if="canSelectType"
                  sm="8"
                  md="6"
                  lg="6"
                >
                  <b-form-group
                    label="Type"
                    label-for="export-radio-group"
                  >
                    <b-form-radio-group
                      id="export-radio-group"
                      v-model="is_export"
                      name="is_export"
                      :options="exportOptions"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="6">
                  <b-form-group
                    label="Document number"
                    label-for="document-number"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Document number"
                      rules="required"
                    >
                      <b-form-input
                        id="document-number"
                        v-model="document_number"
                        name="document-number"
                        :state="errors.length > 0 ? false:null"
                        autocomplete="off"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="6">
                  <b-form-group
                    label="Origin"
                    label-for="origin"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Origin"
                      :rules="{ required: is_export }"
                    >
                      <v-select
                        id="origin"
                        ref="origin"
                        v-model="origin"
                        :options="airportsOptions"
                        name="origin"
                        placeholder="Type to search..."
                        :state="errors.length > 0 ? false:null"
                        @search="fetchAirports"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col cols="6">
                  <b-form-group
                    label="Destination"
                    label-for="destination"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Destination"
                      :rules="{ required: is_export }"
                    >
                      <v-select
                        id="destination"
                        ref="destination"
                        v-model="destination"
                        :options="airportsOptions"
                        name="destination"
                        placeholder="Type to search..."
                        :state="errors.length > 0 ? false:null"
                        @search="fetchAirports"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col
                  v-if="!is_export"
                  cols="6"
                  sm="8"
                  md="6"
                  lg="6"
                >
                  <b-form-group
                    label="Carrier"
                    label-for="carrier"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Carrier"
                      class="w-50"
                    >
                      <v-select
                        id="carrier"
                        ref="carrier"
                        v-model="carrier"
                        :placeholder="carriersOptions.length > 0 ? 'Select carrier' : ''"
                        :disabled="carriersOptions.length === 0"
                        :options="carriersOptions"
                        :loading="carriersListLoading"
                        name="carrier"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                    <div
                      v-if="carriersOptions.length === 0 && carriersListLoading !== true"
                      class="text-muted text-sm"
                    >
                      <span v-if="!companiesList.length || (companiesList.length && companyId && !carriersOptions.length)">
                        Add carriers
                        <router-link :to="{name:'carriers'}">
                          here
                        </router-link>
                      </span>
                      <span v-else>Carriers list will be loaded once company is selected</span>
                    </div>
                  </b-form-group>
                </b-col>
                <b-col
                  v-if="!is_export"
                  cols="6"
                  sm="8"
                  md="6"
                  lg="6"
                >
                  <b-form-group
                    label="Entry country"
                    label-for="entry_country"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Entry country"
                      :rules="{ required: !is_export }"
                    >
                      <v-select
                        id="entry_country"
                        v-model="entry_country"
                        :options="filteredCustoms"
                        name="entry_country"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  v-if="companyList.length > 1 && !is_export"
                  cols="6"
                  sm="8"
                  md="6"
                  lg="6"
                >
                  <b-form-group
                    label="Company"
                    label-for="company"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Company"
                      rules="required"
                    >
                      <v-select
                        id="company"
                        v-model="companyId"
                        :clearable="false"
                        :options="companiesList"
                        :disabled="!!$props.shipmentId"
                        @input="getCarriersList"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  v-if="canAddGrossWeight"
                  cols="6"
                >
                  <b-form-group
                    label="Document gross weight"
                    label-for="document-gross-weight"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Document gross weight"
                      rules="required"
                    >
                      <b-form-input
                        id="document-gross-weight"
                        v-model="document_gross_weight"
                        name="document-gross-weight"
                        :disabled="is_pre_declaration_done"
                        :state="errors.length > 0 ? false:null"
                        autocomplete="off"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row v-if="canSelectDeclarationType">
                <b-col
                  sm="8"
                  md="6"
                  lg="6"
                >
                  <b-form-group
                    label="Declaration type"
                    label-for="declaration-type-radio-group"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Declaration type"
                      rules="required"
                    >
                      <b-form-radio-group
                        id="declaration-type-radio-group"
                        v-model="declaration_type"
                        name="declaration_type"
                        :options="declarationTypes"
                        :state="errors.length > 0 ? false:null"
                        :disabled="shipmentId"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row class="mt-1">
                <b-col>
                  <b-overlay
                    :show="formSubmission"
                    rounded
                    opacity="0.6"
                    spinner-small
                    spinner-variant="primary"
                    class="d-inline-block"
                  >
                    <b-button
                      id="submit-shipment"
                      type="button"
                      variant="primary"
                      @click="validationForm"
                    >
                      Submit
                    </b-button>
                  </b-overlay>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-card>
      </b-overlay>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BOverlay,
  BFormRadioGroup,
} from 'bootstrap-vue'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate/dist/vee-validate.full'
import shipmentModel from '@/views/shipments/shipmentModel'
import vSelect from 'vue-select'
import FloatingTitleBadge from '@/layouts/components/FloatingTitleBadge.vue'

export default {
  components: {
    BFormRadioGroup,
    BRow,
    BCol,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BCard,
    BOverlay,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    FloatingTitleBadge,
  },
  props: {
    shipmentId: {
      type: [String, Number],
      required: false,
    },
  },
  data() {
    return {
      timer: null,
      documentTypeList: [],
      loading: false,
      formSubmission: false,
      carriersListLoading: false,
      is_pre_declaration_done: false,
      document_number: '',
      document_gross_weight: '',
      origin: null,
      destination: null,
      carrier: null,
      is_export: false,
      declaration_type: null,
      entry_country: '',
      airportsOptions: [],
      carriersOptions: [],
      customsOptions: [],
      documentTypes: [],
      companyList: [],
      companyId: null,
      airportsTyping: false,
      carriersTyping: false,
      exportOptions: [
        { text: 'Import', value: false },
        { text: 'Export', value: true },
      ],
      declarationTypes: [
        { text: 'H7', value: 'H7' },
        { text: 'H1', value: 'H1' },
      ],
    }
  },
  computed: {
    ensCompliant() {
      return this.document_number !== ''
        && this.origin !== null
        && this.destination !== null
        && this.carrier !== null
        && this.entry_country !== ''
    },
    ensLabelMessage() {
      return this.ensCompliant ? 'ENS compliant' : 'not compliant to ENS format'
    },
    companiesList() {
      const filtered = []
      this.companyList.forEach(item => {
        filtered.push({
          label: item.name,
          value: item.id,
        })
      })

      return filtered
    },
    filteredCustoms() {
      if (this.declaration_type === 'H1') {
        return this.customsOptions.filter(x => this.$classifiers().classifierContains('h1_countries', x.value))
      }
      return this.customsOptions
    },
    company_id() {
      return this.companyId?.value || this.$activeCompany().data.company.id
    },
    selectedCompany() {
      if (
        (this.companyList.length > 1 && this.companyId)
        || (this.companyList.length <= 1 && this.company_id)
      ) {
        return this.companyList.find(x => x.id === this.company_id)
      }
      return null
    },
    canAddGrossWeight() {
      return this.selectedCompany && this.selectedCompany.config?.adjustItemsWeight
    },
    canSelectDeclarationType() {
      return !this.is_export && this.selectedCompany && this.selectedCompany.config?.h1
    },
    canSelectType() {
      return this.selectedCompany && this.selectedCompany.tags?.includes('Can make export shipments')
    },
  },
  watch: {
    filteredCustoms() {
      // entry country can only be from h1 countries list
      if (this.declaration_type === 'H1' && this.entry_country) {
        this.entry_country = this.filteredCustoms.find(x => x.value === this.selectedCompany.country)
      }
    },
  },
  async created() {
    this.$data.companyList = this.$profile().getUserAndAuthorizedCompanies()
    if (
      (this.$data.companyList.length <= 1 && !this.is_export)
      || (this.$props.shipmentId && this.company_id)
    ) {
      await this.getCarriersList()
    }
    await this.$http.get('/v1/customs/available')
      .then(response => {
        const availableCustoms = response.data.data
        this.customsOptions = availableCustoms.map(customs => ({ value: customs.country_code, label: customs.name }))
      })
    if (this.$props.shipmentId) {
      this.loading = true
      shipmentModel().getShipment(this.$props.shipmentId, this)
        .then(response => {
          this.companyId = this.companiesList.find(x => x.value === response.data.company_id)
          this.document_number = response.data.document_number
          this.document_gross_weight = response.data.document_gross_weight
          this.is_pre_declaration_done = response.data.is_pre_declaration_done
          this.declaration_type = response.data.declaration_type
          if (response.data.origin_airport) {
            this.origin = this.getAiportOption(response.data.origin_airport)
            this.airportsOptions.push(this.origin)
          }
          if (response.data.destination_airport) {
            this.destination = this.getAiportOption(response.data.destination_airport)
            if (this.airportsOptions.find(x => x.value === this.destination.value) === undefined) {
              this.airportsOptions.push(this.destination)
            }
          }
          if (response.data.carrier) {
            if (this.carriersOptions.find(x => x.value === response.data.carrier.id) === undefined) {
              this.carriersOptions.push(this.getCarrierOption(response.data.carrier))
            }
            this.carrier = this.getCarrierOption(response.data.carrier)
          }
          if (response.data.entry_country && this.customsOptions) {
            this.entry_country = this.customsOptions.find(x => x.value === response.data.entry_country)
          }
          this.loading = false
          if (this.canSelectType) {
            this.is_export = response.data.is_export
          }
        })
    }
  },
  methods: {
    getCarriersList() {
      if (!this.shipmentId && this.companiesList.length > 1 && !this.companyId) return

      this.carriersListLoading = true
      this.$http.get(`/v1/companies/${this.company_id}/carriers`)
        .then(response => {
          const carriersList = response.data.data
          this.carriersOptions = carriersList.map(carrier => (this.getCarrierOption(carrier)))
          this.carriersListLoading = false
          if (this.carriersOptions.length === 1) {
            [this.carrier] = this.carriersOptions
          }
        })
    },
    getAiportOption(airport) {
      if (airport) {
        return {
          value: airport.id,
          label: `${airport.code} ${airport.name}, ${airport.country_code}`,
        }
      }
      return null
    },
    fetchAirports(search, loading) {
      if (search.length >= 2 && !this.airportsTyping) {
        loading(true)
        clearTimeout(this.timer)
        this.timer = setTimeout(() => {
          this.airportsTyping = true
          this.$http.get(`/v1/airports?keyword=${encodeURIComponent(search)}`)
            .then(response => {
              loading(false)
              this.airportsTyping = false
              const airportsList = response.data.data
              this.airportsOptions = airportsList.map(airport => (this.getAiportOption(airport)))
            })
        }, 500)
      }
    },
    getCarrierOption(carrier) {
      if (carrier) {
        return {
          value: carrier.id,
          label: `${carrier.code} ${carrier.name}`,
        }
      }
      return null
    },
    validationForm() {
      this.$refs.shipmentValidation.validate().then(success => {
        if (!success) {
          return
        }
        this.editShipment()
      })
    },
    editShipment() {
      let queryHandle = {}
      this.formSubmission = true
      let declarationType = null
      if (!this.is_export) {
        declarationType = this.$data.declaration_type || 'H7'
      }

      const data = {
        document_number: this.$data.document_number,
        document_gross_weight: this.canAddGrossWeight && !this.is_pre_declaration_done ? this.$data.document_gross_weight : null,
        origin: this.$data.origin ? this.$data.origin.value : null,
        destination: this.$data.destination ? this.$data.destination.value : null,
        carrier: this.$data.carrier ? this.$data.carrier.value : null,
        entry_country: this.$data.entry_country ? this.$data.entry_country.value : null,
        is_export: this.$data.is_export,
        declaration_type: declarationType,
      }
      if (!this.$props.shipmentId) {
        queryHandle = this.$http.post(`/v1/companies/${this.company_id}/shipments`, data)
      } else {
        queryHandle = this.$http.put(`/v1/companies/${this.company_id}/shipments/${this.$props.shipmentId}`, data)
      }
      queryHandle.then(response => {
        if (!this.$props.shipmentId) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Shipment added',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          this.$router.push({ name: 'shipment', params: { shipmentId: response.data.shipment_id } })
        } else {
          this.formSubmission = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Shipment edited',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          this.$router.push({ name: 'shipment' })
        }
      })
        .catch(error => {
          this.formSubmission = false
          if (error.response.data.errors) {
            Object.keys(error.response.data.errors).forEach(key => {
              const value = JSON.stringify(error.response.data.errors[key][0])

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `${key} = ${value}`,
                  icon: 'EditIcon',
                  variant: 'danger',
                },
              })
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.response.data.message,
                icon: 'EditIcon',
                variant: 'danger',
              },
            })
          }
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
