<template>
  <div>
    <b-row class="overflow-x-scroll">
      <b-table
        :items="parcel.items"
        :fields="tableFields"
        class="compact-headers mx-1"
      >
        <template #cell(rowNumber)="data">
          {{ data.index + 1 }}
        </template>

        <template #cell(description)="data">
          <div v-if="disabled">
            {{ data.item.description }}
          </div>
          <div v-else>
            <validation-provider
              #default="{ errors }"
              name="Description"
              rules="required"
            >
              <div class="d-flex align-items-center">
                <b-form-input
                  id="description"
                  v-model="data.item.description"
                  v-b-tooltip.hover.top="issueDescription(data.item.issues, 'description')"
                  :class="issueClass(data.item.issues, 'description')"
                  :state="errors.length > 0 ? false:null"
                  class="mr-1"
                />

                <feather-icon
                  v-if="data.item.description !== data.item.original_description"
                  v-b-tooltip.hover.right="data.item.original_description"
                  icon="AlertCircleIcon"
                  class="d-inline"
                  size="1.5x"
                />

              </div>

            </validation-provider>
          </div>
        </template>

        <template #cell(hs_code)="data">
          <div v-if="disabled">
            {{ data.item.hs_code }}
          </div>
          <div v-else>
            <validation-provider
              #default="{ errors }"
              name="HS Code"
              rules="required"
            >
              <b-form-input
                id="hs-code"
                v-model="data.item.hs_code"
                v-b-tooltip.hover.left="issueDescription(data.item.issues, 'hs_code')"
                :class="issueClass(data.item.issues, 'hs_code')"
                :state="errors.length > 0 ? false:null"
              />
            </validation-provider>
          </div>
        </template>

        <template #cell(quantity)="data">
          <div v-if="disabled">
            {{ data.item.quantity }}
          </div>
          <div v-else>
            <validation-provider
              #default="{ errors }"
              name="Quantity"
              :rules="'required|minvalue:1'"
            >
              <b-form-input
                id="quantity"
                v-model="data.item.quantity"
                v-b-tooltip.hover.left="issueDescription(data.item.issues, 'quantity')"
                type="number"
                :class="issueClass(data.item.issues, 'quantity')"
                :state="errors.length > 0 ? false:null"
              />
            </validation-provider>
          </div>
        </template>

        <template #cell(value)="data">
          <div v-if="disabled">
            {{ data.item.value }}
          </div>
          <div v-else>
            <validation-provider
              #default="{ errors }"
              name="Value"
              rules="required"
            >
              <b-form-input
                id="value"
                v-model="data.item.value"
                v-b-tooltip.hover.left="issueDescription(data.item.issues, 'value')"
                :class="issueClass(data.item.issues, 'value')"
                :state="errors.length > 0 ? false:null"
              />
            </validation-provider>
          </div>
        </template>

        <template #cell(value_currency)="data">
          <div v-if="disabled">
            {{ data.item.value_currency }}
          </div>
          <div v-else>
            <validation-provider
              #default="{ errors }"
              name="Currency"
              rules="required|alpha|length:3"
            >
              <b-form-input
                id="value-currency"
                v-model="data.item.value_currency"
                v-b-tooltip.hover.left="issueDescription(data.item.issues, 'value_currency')"
                :class="issueClass(data.item.issues, 'value_currency')"
                :state="errors.length > 0 ? false:null"
              />
            </validation-provider>
          </div>
        </template>

        <template #cell(gross_weight)="data">
          <div v-if="disabled">
            {{ data.item.gross_weight }}
          </div>
          <div v-else>
            <validation-provider
              #default="{ errors }"
              name="Gross weight"
              :rules="parcel.parcel_gross_weight ? '' : 'required'"
            >
              <b-form-input
                id="gross-weight"
                v-model="data.item.gross_weight"
                v-b-tooltip.hover.left="issueDescription(data.item.issues, 'gross_weight')"
                class="full-width"
                :class="issueClass(data.item.issues, 'gross_weight')"
                :state="errors.length > 0 ? false:null"
              />
            </validation-provider>
          </div>
        </template>

        <template #cell(net_weight)="data">
          <div v-if="disabled">
            {{ data.item.net_weight }}
          </div>
          <div v-else>
            <validation-provider
              #default="{ errors }"
              name="Net weight"
              rules="required"
            >
              <b-form-input
                id="net-weight"
                v-model="data.item.net_weight"
                v-b-tooltip.hover.left="issueDescription(data.item.issues, 'net_weight')"
                :class="issueClass(data.item.issues, 'net_weight')"
                class="full-width"
                :state="errors.length > 0 ? false:null"
              />
            </validation-provider>
          </div>
        </template>

        <template #cell(supplementary_unit)="data">
          <div v-if="disabled">
            {{ data.item.supplementary_unit }}
          </div>
          <div v-else>
            <validation-provider
              #default="{ errors }"
              name="Supplementary Unit"
            >
              <b-form-input
                id="supplementary-unit"
                v-model="data.item.supplementary_unit"
                v-b-tooltip.hover.left="issueDescription(data.item.issues, 'supplementary_unit')"
                :class="issueClass(data.item.issues, 'supplementary_unit')"
                :state="errors.length > 0 ? false:null"
              />
            </validation-provider>
          </div>
        </template>

        <template #cell(origin_country)="data">
          <div v-if="disabled">
            {{ data.item.origin_country }}
          </div>
          <div v-else>
            <validation-provider
              #default="{ errors }"
              name="Country of origin"
            >
              <b-form-input
                id="origin-country"
                v-model="data.item.origin_country"
                v-b-tooltip.hover.left="issueDescription(data.item.issues, 'origin_country')"
                :class="issueClass(data.item.issues, 'origin_country')"
                :state="errors.length > 0 ? false:null"
              />
            </validation-provider>
          </div>
        </template>

        <template #cell(supplementary_unit_value)="data">
          <div v-if="disabled">
            {{ data.item.supplementary_unit_value }}
          </div>
          <div v-else>
            <validation-provider
              #default="{ errors }"
              name="Supplementary Unit Value"
            >
              <b-form-input
                id="supplementary-unit-value"
                v-model="data.item.supplementary_unit_value"
                v-b-tooltip.hover.left="issueDescription(data.item.issues, 'supplementary_unit_value')"
                :class="issueClass(data.item.issues, 'supplementary_unit_value')"
                :state="errors.length > 0 ? false:null"
              />
            </validation-provider>
          </div>
        </template>

        <template #cell(action)="data">
          <b-button
            variant="outline-danger"
            style="bottom: 4px"
            @click="deleteItem(data.item.id || data.item.new_item)"
          >
            <feather-icon
              icon="TrashIcon"
              class="d-inline"
            />
          </b-button>
        </template>
      </b-table>
    </b-row>
    <b-row>
      <!-- Errors -->
      <b-col
        v-if="error"
        lg="1"
      >
        <label>Errors</label>
        <b-alert
          show
          variant="danger"
        >
          {{ error.message }}
        </b-alert>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BAlert, VBTooltip,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'

export default {
  components: {
    BButton,
    ValidationProvider,
    BCol,
    BRow,
    BFormInput,
    BTable,
    BAlert,
  },
  props: {
    parcel: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
    },
    declaration: {
      type: Object,
      default: () => {},
    },
    error: {
      type: Object,
      default: () => {},
    },
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    let fields = [
      { key: 'rowNumber', label: '#' },
      { key: 'description', label: 'Description' },
      { key: 'hs_code', label: 'HS Code' },
      { key: 'quantity', label: 'Quantity' },
      { key: 'value', label: 'Value' },
      { key: 'value_currency', label: 'Currency' },
      { key: 'gross_weight', label: 'Gross weight, kg' },
    ]
    if (this.parcel.declaration_type === 'H1') {
      fields.push({ key: 'origin_country', label: 'Country of origin' })
      fields.push({ key: 'net_weight', label: 'Net weight' })
    }
    if (
      this.parcel.shipment?.entry_country === 'LV'
      || this.declaration?.customs_country === 'LV'
    ) {
      fields = fields.filter(field => field.key !== 'quantity')
    }
    return {
      fields: fields,
    }
  },
  computed: {
    totalItems() {
      return this.parcel.items.length
    },
    tableFields() {
      const optionalHeader = []

      if (
        this.parcel.shipment?.entry_country === 'RO'
        || this.declaration?.customs_country === 'RO'
      ) {
        optionalHeader.push({ key: 'supplementary_unit', label: 'Supplementary Unit' })
        optionalHeader.push({ key: 'supplementary_unit_value', label: 'Supplementary Unit Value' })
      }

      const actionHeader = !this.disabled && this.totalItems > 1 ? [{ key: 'action', label: '' }] : []

      return [...this.fields, ...optionalHeader, ...actionHeader]
    },
  },
  methods: {
    deleteItem(id) {
      this.$emit('remove', id)
    },
    issueDescription(issues, field) {
      if (issues?.length) {
        const index = issues.findIndex(item => item.field === field)

        if (index !== -1) {
          return issues[index].message
        }

        return ''
      }
      return ''
    },
    issueClass(issues, field) {
      if (issues?.length) {
        const index = issues.findIndex(item => item.field === field)

        if (index !== -1) {
          return issues[index].type === 'warning' ? 'input-warning' : 'input-danger'
        }

        return ''
      }
      return ''
    },
  },
}
</script>
<style scoped lang="scss">
@import '@core/scss/base/bootstrap-extended/variables';

.compact-headers td, .compact-headers th {
  padding-left: 8px;
  padding-right: 8px;
}
.input-warning {
  background: lighten($warning, 35%);
  border: 1px solid $warning;
}
.input-danger {
  background: lighten($danger, 30%);
  border: 1px solid $danger;
}
</style>
