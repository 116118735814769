<template>
  <div>
    <edit-parcel-items
      v-if="selectedParcel"
      id="edit-parcel-items"
      :parcel="selectedParcel"
      :event-hub="eventHub"
      :shipment="shipment"
      :readonly="!canEdit"
    />
    <confirm-modal
      v-if="confirmGenerateUnitValues"
      id="generate-unit-values"
      title="Are you sure you want to proceed?"
      confirmation="Are you sure you want to assign a random Supplementary Unit value for all parcels? Incorrectly filling the Supplementary Unit on the H7 declaration can result in customs penalties and delays. Proceeding is at your own risk and responsibility."
      ok-button="Generate"
      @submit="generateGenerateUnitValues()"
    />
    <b-row>
      <b-col class="mt-2">
        <b-card-text>
          Missing supplementary unit values
        </b-card-text>
      </b-col>
      <b-col class="text-right">
        <b-button
          v-if="parcels.length > 0"
          v-b-modal.generate-unit-values
          variant="outline-success"
          class="m-1"
          :disabled="!canEdit"
          @click="confirmGenerateUnitValues = true"
        >
          Generate supplementary unit values
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-table
          :items="parcels"
          responsive
          :fields="fields"
          stacked="sm"
          show-empty
          empty-text="No matching records found"
          class="mb-0"
        >
          <template #cell(actions)="data">
            <div v-if="data.item.tracking_number">
              <b-button-group>
                <view-parcel-button
                  v-if="!canEdit"
                  :parcel="data.item"
                  :event-hub="eventHub"
                />
                <b-button
                  v-if="canEdit"
                  v-b-modal.edit-parcel-items
                  type="button"
                  variant="warning"
                  @click="selectParcel(data.item)"
                >
                  <feather-icon icon="EditIcon" />
                </b-button>
              </b-button-group>
            </div>
          </template>

          <template #cell(tracking_number)="data">
            {{ data.item.tracking_number }}
          </template>

          <template #cell(hs_codes)="data">
            <div>
              {{ data.item.items.map(function (parcelItem) {return parcelItem.hs_code}).join(', ') }}
            </div>
          </template>

          <template #cell(descriptions)="data">
            <div>
              {{ data.item.items.map(function (parcelItem) {return parcelItem.description}).join(', ') }}
            </div>
          </template>

          <template #cell(value)="data">
            <div
              v-if="typeof data.item.tracking_number !== 'undefined'"
            >
              {{ data.item.items.reduce(function (sum, parcelItem) {return sum + parseFloat(parcelItem.value)}, 0) | formatNumber(2) }}
            </div>
          </template>

        </b-table>

        <pagination
          v-show="!loading && meta"
          :event-hub="eventHub"
          :per-page="perPage"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BButton, BButtonGroup,
  BCardText,
  BCol,
  BRow,
  BTable,
} from 'bootstrap-vue'
import EditParcelItems from '@/views/shipments/declarations/modals/EditParcelItems.vue'
import Vue from 'vue'
import ViewParcelButton from '@/views/shipments/declarations/components/ViewParcelButton.vue'
import ConfirmModal from '@core/components/confirmation/confirmModal.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { searchQuery } from '@core/utils/filter'
import Pagination from '@/layouts/components/Pagination/Pagination.vue'

export default {
  components: {
    Pagination,
    ConfirmModal,
    ViewParcelButton,
    BButtonGroup,
    BButton,
    EditParcelItems,
    BCardText,
    BCol,
    BRow,
    BTable,
  },
  props: {
    shipment: {
      type: Object,
      default: () => {},
    },
    eventHub: {
      default: () => new Vue(),
    },
    filters: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      loading: true,
      selectedParcel: null,
      parcels: [],
      confirmGenerateUnitValues: false,
      page: 1,
      perPage: 100,
      meta: {},
      fields: [
        { key: 'actions', label: '' },
        { key: 'tracking_number', label: 'Tracking code' },
        { key: 'descriptions', label: 'Descriptions' },
        { key: 'hs_codes', label: 'HS Codes' },
        { key: 'value', label: 'Value' },
      ],
    }
  },
  computed: {
    canEdit() {
      return this.$permissions().hasPermission('edit shipment')
    },
    url() {
      let url = `/v1/companies/${this.shipment.company_id}/shipments/${this.shipment.id}/missing-supplementary-unit-values`
      url += `?page=${this.page}`
      url += `&per_page=${this.perPage}`
      return url
    },
  },
  watch: {
    filters() {
      this.fetchData()
    },
  },
  created() {
    this.fetchData()
    this.eventHub.$on('set-view-parcel', this.viewParcel)
    this.eventHub.$on('update-parcel-items', this.handleUpdateParcelItems)
  },
  mounted() {
    this.eventHub.$on('pageChange', this.handlePageChange)
  },
  beforeDestroy() {
    this.eventHub.$off('set-view-parcel', this.viewParcel)
    this.eventHub.$off('update-parcel-items', this.handleUpdateParcelItems)
    this.eventHub.$off('pageChange', this.handlePageChange)
  },
  methods: {
    handlePageChange(newPage) {
      this.page = newPage
      this.fetchData()
    },
    handleUpdateParcelItems() {
      this.selectedParcel = null
      this.fetchData()
      this.eventHub.$emit('refresh-statistics')
    },
    generateGenerateUnitValues() {
      this.confirmGenerateUnitValues = false
      this.$http.post(`/v1/companies/${this.shipment.company_id}/shipments/${this.shipment.id}/missing-supplementary-unit-values`)
        .then(() => {
          this.eventHub.$emit('refresh-statistics')
          this.parcels = []
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Supplementary unit values generated',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Generation failed',
              icon: 'EditIcon',
              variant: 'danger',
            },
          })
        })
    },
    viewParcel(declaration, parcel) {
      this.selectParcel(parcel)
    },
    selectParcel(parcel) {
      this.selectedParcel = parcel
      this.selectedParcel.shipment = {}
      this.selectedParcel.shipment.entry_country = this.shipment.entry_country
    },
    fetchData() {
      this.loading = true
      this.$http.get(searchQuery(this.url, this.filters))
        .then(response => {
          this.parcels = response.data.data
          this.$props.eventHub.$emit('updateMeta', response.data.meta)
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>
