<template>

  <div>
    <b-card
      id="filters-card"
      title="Filters"
    >
      <b-form @keyup.enter="fetchList">
        <b-row>
          <b-col v-if="companiesList.length > 1">
            <b-form-group
              label="Company"
              label-for="company"
            >
              <v-select
                id="company"
                v-model="filters.companyIds"
                :reduce="option => option.value"
                label="label"
                multiple
                :options="companiesList"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <validation-provider
              #default="{ errors }"
              name="Country"
              rules="min:2|max:2"
            >
              <b-form-group
                label="Country"
                label-for="country"
              >
                <b-form-input
                  id="country"
                  v-model="filters.country"
                  name="country"
                  autocomplete="off"
                  :state="errors.length > 0 ? false:null"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col>
            <b-form-group
              label="Type"
              label-for="type"
            >
              <v-select
                id="type"
                v-model="filters.type"
                :reduce="option => option.value"
                label="label"
                multiple
                :options="typeList"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              label="HS code"
              label-for="hs-code"
            >
              <b-form-input
                id="hs-code"
                v-model="filters.hsCode"
                name="HS code"
                autocomplete="off"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="text-right">
            <b-button
              type="button"
              variant="primary"
              @click="fetchList()"
            >
              Filter
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div
        v-if="$permissions().hasPermission('create special-commodities')"
        class="m-2"
      >

        <!-- Table Top -->
        <b-row>

          <!-- Search -->
          <b-col
            cols="12"
            md="12"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                variant="primary"
                @click="$router.push('/special-commodities/add')"
              >
                <span class="text-nowrap">Add new</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <div class="overflow-x-scroll">
        <b-table
          ref="refUserListTable"
          class="position-relative"
          :items="commodityList"
          :fields="tableColumns"
          primary-key="id"
          show-empty
          empty-text="No matching records found"
        >

          <!-- Column: Created at -->
          <template #cell(created_at)="data">
            {{ data.item.created_at | formatDate }}
          </template>

          <template #cell(type)="data">
            <b-badge
              v-if="data.item.type === 'reduced_vat'"
              pill
              variant="light-primary"
            >
              Reduced VAT Tax
            </b-badge>
            <b-badge
              v-if="data.item.type === 'prohibited'"
              pill
              variant="light-danger"
            >
              Prohibited
            </b-badge>
            <b-badge
              v-if="data.item.type === 'replace_hs_code'"
              pill
              variant="light-primary"
            >
              Replace HS code
            </b-badge>
          </template>

          <template #cell(additional_data)="data">
            <b-badge
              v-if="data.item.additional_data && data.item.additional_data.reduced_vat"
              pill
              variant="light-primary"
            >
              ReducedVat code: {{ data.item.additional_data.reduced_vat }}
            </b-badge>
            <b-badge
              v-if="data.item.additional_data && data.item.additional_data.origin_country"
              pill
              variant="light-danger"
            >
              Origin country: {{ data.item.additional_data.origin_country }}
            </b-badge>
            <b-badge
              v-if="data.item.additional_data && data.item.additional_data.replace_hs_code_to"
              pill
              variant="light-primary"
            >
              Replace HS code to: {{ data.item.additional_data.replace_hs_code_to }}
            </b-badge>
          </template>

          <template #cell(logo)="data">
            <CompanyThumb
              :logo="data.item.company.logo"
              :name="data.item.company.name"
            />
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-col>
              <b-button-group>
                <b-button
                  v-if="$permissions().hasPermission('edit special-commodities')"
                  variant="primary"
                  size="sm"
                  @click="edit(data.item.id)"
                >
                  <feather-icon
                    icon="EditIcon"
                    class="d-inline"
                  />
                </b-button>
                <b-button
                  v-if="$permissions().hasPermission('delete special-commodities')"
                  v-b-tooltip.hover.top="'Delete special good'"
                  variant="outline-danger"
                  size="sm"
                  @click="deleteGoods(data.item.id)"
                >
                  <feather-icon
                    icon="TrashIcon"
                    class="d-inline"
                  />
                </b-button>
              </b-button-group>
            </b-col>
          </template>

        </b-table>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BButton, BTable, VBTooltip, BBadge, BButtonGroup, BForm, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import handleError from '@/views/components/errorHandler'
import CompanyThumb from '@/views/components/CompanyThumb.vue'
import vSelect from 'vue-select'
import { getSelectedCompanies, parseFilters, updateSelectedCompanies } from '@core/utils/filter'
import { ValidationProvider } from 'vee-validate/dist/vee-validate.full'

export default {
  components: {
    ValidationProvider,
    BFormInput,
    vSelect,
    BFormGroup,
    BForm,
    CompanyThumb,
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BBadge,
    BButtonGroup,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    const tableColumns = [
      { key: 'logo', label: 'Company', sortable: false },
      { key: 'country_code', label: 'Country', sortable: true },
      { key: 'hs_code', label: 'HS Code', sortable: true },
      { key: 'type', label: 'Type', sortable: true },
      { key: 'description', label: 'Description', sortable: true },
      { key: 'additional_data', label: 'Additional', sortable: true },
      { key: 'created_at', sortable: true },
    ]
    if (
      this.$permissions().hasPermission('edit special-commodities')
      || this.$permissions().hasPermission('delete special-commodities')
    ) {
      tableColumns.push({ key: 'actions' })
    }
    return {
      companyList: [],
      typeList: [
        { label: 'Reduced VAT Tax', value: 'reduced_vat' },
        { label: 'Prohibited', value: 'prohibited' },
        { label: 'Replace HS code', value: 'replace_hs_code' },
      ],
      specialCommodities: [],
      tableColumns: tableColumns,
      filters: {
        country: null,
        type: null,
        hsCode: null,
        companyIds: getSelectedCompanies(),
      },
    }
  },
  computed: {
    companiesList() {
      const filtered = []
      this.companyList.forEach(item => {
        filtered.push({
          label: item.name,
          value: item.id,
        })
      })

      return filtered
    },
    commodityList() {
      return this.specialCommodities
    },
  },
  async created() {
    this.fetchList()
    this.companyList = this.$profile().getUserAndAuthorizedCompanies()
  },
  methods: {
    edit(id) {
      this.$router.push({ name: 'edit-special-commodities', query: { specialCommoditiesId: id } })
    },
    deleteGoods(id) {
      this.$http.delete(`/v1/special-commodities/${id}`)
        .then(() => {
          this.$router.push({ name: 'special-commodities' })
          this.fetchList()
        })
        .catch(error => {
          handleError(error, this.$toast)
        })
    },
    fetchList() {
      updateSelectedCompanies(this.filters.companyIds)
      this.$http.get('/v1/special-commodities', {
        params: parseFilters(this.filters),
      })
        .then(response => {
          this.$data.specialCommodities = response.data.data
        })
        .catch(error => {
          handleError(error, this.$toast)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
