<template>
  <b-row>
    <b-col>
      <b-card>
        <validation-observer ref="warehousesValidation">
          <b-form>
            <b-row>
              <b-col>
                <b-tabs>

                  <!-- Tab: Basic info -->
                  <b-tab active>
                    <template #title>
                      <b-badge
                        v-if="tabErrors.details > 0"
                        class="mr-1"
                        pill
                        variant="danger"
                      >
                        {{ tabErrors.details }}
                      </b-badge>
                      <feather-icon
                        icon="InfoIcon"
                        size="16"
                        class="mr-0 mr-sm-50"
                      />
                      <span class="d-none d-sm-inline">Details</span>
                    </template>

                    <!-- Details form -->
                    <b-row>
                      <!-- Field: name -->
                      <b-col
                        lg="6"
                        md="12"
                      >
                        <b-form-group
                          label="Name"
                          label-for="name"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="Name"
                            rules="required"
                          >
                            <b-form-input
                              id="name"
                              v-model="warehouse.name"
                              name="name"
                              :state="errors.length > 0 ? false:null"
                              autocomplete="off"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Field: Code -->
                      <b-col>
                        <b-form-group
                          label="Code"
                          label-for="code"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="Code"
                            rules="required"
                          >
                            <b-form-input
                              id="code"
                              v-model="warehouse.code"
                              name="code"
                              :state="errors.length > 0 ? false:null"
                              autocomplete="off"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Field: type -->
                      <b-col
                        lg="6"
                        md="12"
                      >
                        <b-form-group
                          label="Type of location"
                          label-for="type"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="Type of location"
                            rules="required"
                          >
                            <v-select
                              id="type"
                              v-model="warehouse.type"
                              label="text"
                              :options="typeOptions"
                              :reduce="option => option.value"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Field: customs_office -->
                      <b-col
                        lg="6"
                        md="12"
                      >
                        <b-form-group
                          label="Customs office"
                          label-for="customs-office"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="Customs office"
                            rules="required|length:8"
                          >
                            <b-form-input
                              id="customs-office"
                              v-model="warehouse.customsOffice"
                              name="customsOffice"
                              :state="errors.length > 0 ? false:null"
                              autocomplete="off"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Field: qualifier -->
                      <b-col
                        lg="6"
                        md="12"
                      >
                        <b-form-group
                          label="Qualifier of identification"
                          label-for="qualifier"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="Qualifier of identification"
                            rules="required"
                          >
                            <v-select
                              id="qualifier"
                              v-model="warehouse.qualifier"
                              label="text"
                              :options="qualifierOptions"
                              :reduce="option => option.value"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-tab>

                  <!-- Tab: Address -->
                  <b-tab>
                    <template #title>
                      <b-badge
                        v-if="tabErrors.address > 0"
                        class="mr-1"
                        pill
                        variant="danger"
                      >
                        {{ tabErrors.address }}
                      </b-badge>
                      <feather-icon
                        icon="HomeIcon"
                        size="16"
                        class="mr-0 mr-sm-50"
                      />
                      <span class="d-none d-sm-inline">Address</span>
                    </template>
                    <!-- Address form -->
                    <b-row>
                      <!-- Field: country -->
                      <b-col
                        lg="6"
                        md="12"
                      >
                        <b-form-group
                          label="Country"
                          label-for="country"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="Country"
                            rules="required"
                          >
                            <b-form-input
                              id="country"
                              v-model="warehouse.country"
                              name="country"
                              :state="errors.length > 0 ? false:null"
                              autocomplete="off"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Field: region -->
                      <b-col
                        lg="6"
                        md="12"
                      >
                        <b-form-group
                          label="Region"
                          label-for="region"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="Region"
                            rules="required"
                          >
                            <b-form-input
                              id="region"
                              v-model="warehouse.region"
                              name="region"
                              :state="errors.length > 0 ? false:null"
                              autocomplete="off"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Field: city -->
                      <b-col
                        lg="6"
                        md="12"
                      >
                        <b-form-group
                          label="City"
                          label-for="city"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="City"
                            rules="required"
                          >
                            <b-form-input
                              id="city"
                              v-model="warehouse.city"
                              name="city"
                              :state="errors.length > 0 ? false:null"
                              autocomplete="off"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Field: Postal code -->
                      <b-col
                        lg="6"
                        md="12"
                      >
                        <b-form-group
                          label="Postal code"
                          label-for="postal-code"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="Postal code"
                            rules="required"
                          >
                            <b-form-input
                              id="postal-code"
                              v-model="warehouse.postalCode"
                              name="postal-code"
                              :state="errors.length > 0 ? false:null"
                              autocomplete="off"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Field: street and number -->
                      <b-col
                        lg="6"
                        md="12"
                      >
                        <b-form-group
                          label="Street and number"
                          label-for="streetAndNumber"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="Street and number"
                            rules="required"
                          >
                            <b-form-input
                              id="streetAndNumber"
                              v-model="warehouse.streetAndNumber"
                              name="streetAndNumber"
                              :state="errors.length > 0 ? false:null"
                              autocomplete="off"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-tab>
                </b-tabs>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-button
                  type="button"
                  variant="primary"
                  @click="validationForm"
                >
                  Submit
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BTabs,
  BTab,
  BRow,
  BCol,
  BCard,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BBadge,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate/dist/vee-validate.full'
import handleError from '@/views/components/errorHandler'

export default {
  components: {
    BTabs,
    BTab,
    BRow,
    BCol,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BCard,
    ValidationProvider,
    ValidationObserver,
    BBadge,
    vSelect,
  },
  data() {
    return {
      warehouse: {
        name: null,
        code: null,
        type: null,
        customsOffice: null,
        qualifier: null,
        country: null,
        region: null,
        city: null,
        postalCode: null,
        streetAndNumber: null,
      },
      typeOptions: this.$classifiers().getOptions('warehouse.types', false),
      qualifierOptions: this.$classifiers().getOptions('warehouse.qualifiers', false),
      inputFieldMapping: {
        details: ['Name', 'Code', 'Qualifier', 'Type', 'Customs office'],
        address: ['Postal code', 'City', 'Country', 'Region', 'Street and number'],
      },
      tabErrors: {
        details: 0,
        address: 0,
      },
    }
  },
  methods: {
    validationForm() {
      // Handle Tab validation
      this.$data.tabErrors = {
        details: 0,
        address: 0,
      }
      this.$refs.warehousesValidation.validate().then(success => {
        const { errors } = this.$refs.warehousesValidation
        Object.keys(errors).forEach(field => {
          if (this.$data.inputFieldMapping.details.includes(field) && errors[field].length > 0) {
            this.$data.tabErrors.details += 1
          }
          if (this.$data.inputFieldMapping.address.includes(field) && errors[field].length > 0) {
            this.$data.tabErrors.address += 1
          }
        })
        // Return if failed
        if (!success) {
          return
        }
        this.create()
      })
    },
    create() {
      this.$http.post(`/v1/companies/${this.$activeCompany().data.company.id}/warehouses`, this.warehouse)
        .then(() => {
          this.$router.push('/warehouses')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Warehouse added',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          handleError(error, this.$toast)
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
