import Vue from 'vue'
import axios from '@/libs/axios'
import { logout } from '@/auth/sanctum'
import router from '@/router'
import useJwt from '@/auth/jwt/useJwt'

export default {
  name: 'ProfileComponent',
  data: {
    user: {},
    timeoutHandle: null,
  },
  install() {
    Vue.prototype.$profile = () => this
  },
  async fetchProfile(callback, failcallback) {
    return axios.get('/v1/user')
      .then(user => {
        this.data.user = user.data.user
        useJwt.setUserData(user.data.user)

        if (this.data.timeoutHandle !== null) {
          clearTimeout(this.data.timeoutHandle)
        }

        // Timeout to send another call.
        const myHandle = this
        if (!user.data.expires) user.data.expires = 120
        this.data.timeoutHandle = setTimeout(() => {
          myHandle.fetchProfile()
        }, user.data.expires * 1000)

        if (callback) {
          callback()
        }
      })
      .catch(() => {
        logout()
        router.push('/v1/login')
        if (failcallback) {
          failcallback()
        }
      })
  },
  getUserAndAuthorizedCompanies() {
    return [...this.data.user.companies, ...this.data.user.authorisations]
      .filter((item, index, self) => index === self.findIndex(t => t.id === item.id))
  },
}
