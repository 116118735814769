var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.parcel)?_c('b-card',{attrs:{"title":"Parcel data"}},[_c('b-row',[_c('b-col',{attrs:{"lg":"3","md":"4"}},[_c('validation-provider',{attrs:{"name":"Tracking code","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v("Tracking code")]),_c('b-form-input',{staticClass:"mr-0 ml-0",attrs:{"disabled":_vm.disabled,"state":errors.length > 0 ? false:null},model:{value:(_vm.parcel.tracking_number),callback:function ($$v) {_vm.$set(_vm.parcel, "tracking_number", $$v)},expression:"parcel.tracking_number"}})]}}],null,false,3537638108)})],1),_c('b-col',{attrs:{"lg":"3","md":"4"}},[_c('label',[_vm._v("Container code")]),_c('b-form-input',{staticClass:"mr-0 ml-0",attrs:{"disabled":_vm.disabled},model:{value:(_vm.parcel.container_code),callback:function ($$v) {_vm.$set(_vm.parcel, "container_code", $$v)},expression:"parcel.container_code"}})],1),_c('b-col',{attrs:{"lg":"3","md":"4"}},[_c('label',[_vm._v("TSD position")]),_c('b-form-input',{staticClass:"mr-0 ml-0",attrs:{"disabled":_vm.disabled,"type":"number"},model:{value:(_vm.parcel.tsd_position),callback:function ($$v) {_vm.$set(_vm.parcel, "tsd_position", $$v)},expression:"parcel.tsd_position"}})],1),(['EE', 'GR', 'HU', 'LV', 'LT', 'RO', 'SK'].includes(_vm.parcel.shipment.entry_country))?_c('b-col',{attrs:{"lg":"3","md":"4"}},[_c('validation-provider',{attrs:{"name":"Transport Cost","rules":_vm.parcel.declaration_type === 'H1' && _vm.parcel.transport_cost_currency ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v("Transport Cost")]),_c('b-form-input',{staticClass:"mr-0 ml-0",attrs:{"disabled":_vm.disabled,"type":"number","state":errors.length > 0 ? false:null},model:{value:(_vm.parcel.transport_cost),callback:function ($$v) {_vm.$set(_vm.parcel, "transport_cost", $$v)},expression:"parcel.transport_cost"}})]}}],null,false,1351426591)})],1):_vm._e(),(['EE', 'GR', 'HU', 'LV', 'LT', 'RO', 'SK'].includes(_vm.parcel.shipment.entry_country))?_c('b-col',{attrs:{"lg":"3","md":"4"}},[_c('validation-provider',{attrs:{"name":"Transport Cost Currency","rules":_vm.parcel.declaration_type === 'H1' && _vm.parcel.transport_cost ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v("Transport Cost Currency")]),_c('b-form-input',{staticClass:"mr-0 ml-0",attrs:{"disabled":_vm.disabled,"state":errors.length > 0 ? false:null},model:{value:(_vm.parcel.transport_cost_currency),callback:function ($$v) {_vm.$set(_vm.parcel, "transport_cost_currency", $$v)},expression:"parcel.transport_cost_currency"}})]}}],null,false,1942430925)})],1):_vm._e(),(['EE', 'LV', 'NL'].includes(_vm.parcel.shipment.entry_country))?_c('b-col',{attrs:{"lg":"3","md":"4"}},[_c('validation-provider',{attrs:{"name":"Packages Count","rules":"required|minvalue:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v("Packages Count")]),_c('b-form-input',{staticClass:"mr-0 ml-0",attrs:{"disabled":_vm.disabled,"type":"number","state":errors.length > 0 ? false:null},model:{value:(_vm.parcel.packages_count),callback:function ($$v) {_vm.$set(_vm.parcel, "packages_count", $$v)},expression:"parcel.packages_count"}})]}}],null,false,2778344127)})],1):_vm._e(),(_vm.parcel.declaration_type === 'H1')?_c('b-col',{attrs:{"lg":"3","md":"4"}},[_c('label',[_vm._v("Parcel gross weight")]),_c('b-form-input',{staticClass:"mr-0 ml-0",attrs:{"disabled":_vm.disabled,"type":"number"},model:{value:(_vm.parcel.parcel_gross_weight),callback:function ($$v) {_vm.$set(_vm.parcel, "parcel_gross_weight", $$v)},expression:"parcel.parcel_gross_weight"}})],1):_vm._e()],1),_c('buyer-data-component',{attrs:{"parcel":_vm.parcel,"disabled":_vm.disabled}}),_c('seller-data-component',{attrs:{"parcel":_vm.parcel,"disabled":_vm.disabled}}),(_vm.parcel.declaration_type === 'H1')?_c('h1-data-component',{attrs:{"parcel":_vm.parcel,"disabled":_vm.disabled}}):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }