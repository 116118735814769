<template>
  <div>
    <b-card
      v-if="companyList.length > 1"
      class="dashboard-filters"
      title="Filters"
    >
      <b-form>
        <b-row>
          <b-col v-if="companyList.length > 1">
            <b-form-group
              label="Company"
              label-for="company"
            >
              <v-select
                id="company"
                v-model="filters.companyIds"
                :reduce="option => option.value"
                label="label"
                multiple
                :options="suggestionList"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-button
              type="button"
              variant="primary"
              :disabled="isStatsLoading"
              @click="reFetchData()"
            >
              Filter
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
    <b-row>
      <b-col>
        <h3 class="mb-2">
          Ongoing declarations
        </h3>
      </b-col>
    </b-row>
    <b-row class="unfinished-declaration-cards">
      <declaration-card
        v-for="statCard in statistics"
        :key="statCard.title"
        :title="statCard.title"
        :amount="statCard.stat"
        :loading="statCard.loading"
        :filters="filters"
        :icon="statCard.icon"
        :class-name="statCard.className"
      />
    </b-row>
  </div>
</template>

<script>
import { BButton, BCard, BCol, BForm, BFormGroup, BRow } from 'bootstrap-vue'
import DeclarationCard from '@/views/dashboard/components/DeclarationCard.vue'
import vSelect from 'vue-select'
import { getSelectedCompanies, parseFilters } from '@core/utils/filter'

export default {
  components: {
    BButton,
    vSelect,
    BCol,
    BFormGroup,
    BForm,
    DeclarationCard,
    BCard,
    BRow,
  },
  data() {
    return {
      statistics: {
        withoutDeclaration: {
          icon: 'BoxIcon',
          title: 'Not sent to customs',
          loading: false,
          className: '',
          stat: 0,
        },
        waitingForCustoms: {
          icon: 'ClockIcon',
          title: 'Waiting for customs',
          loading: false,
          className: 'text-primary',
          stat: 0,
        },
        waitingForArrival: {
          icon: 'TruckIcon',
          title: 'Waiting for arrival',
          loading: false,
          className: 'text-primary',
          stat: 0,
        },
        needsAttention: {
          icon: 'XOctagonIcon',
          title: 'Needs attention',
          loading: false,
          className: 'text-warning',
          stat: 0,
        },
        needsDocuments: {
          icon: 'XOctagonIcon',
          title: 'Needs documents',
          loading: false,
          className: 'text-warning',
          stat: 0,
        },
        heldByCustoms: {
          icon: 'PauseCircleIcon',
          title: 'Held by customs',
          loading: false,
          className: 'text-warning',
          stat: 0,
        },
        availableForReadyForLastMile: {
          icon: 'PackageIcon',
          title: 'Available for last mile',
          loading: false,
          className: 'text-success',
          stat: 0,
        },
      },
      selectedDeclaration: null,
      selectedParcel: null,
      filters: {
        companyIds: getSelectedCompanies(),
      },
      searchFilters: {},
      companyList: [],
    }
  },
  computed: {
    suggestionList() {
      const filtered = []
      this.companyList.forEach(item => {
        filtered.push({
          label: item.name,
          value: item.id,
        })
      })

      return filtered
    },
    isStatsLoading() {
      return Object.values(this.statistics).some(stat => stat.loading === true)
    },
  },
  created() {
    if (this.filters.companyIds.length < 1) {
      this.filters.companyIds = [this.$activeCompany().data.company.id]
    }
    this.searchFilters = { ...this.filters }
    this.reFetchData()
  },
  methods: {
    searchQuery(url, filters) {
      url += `?${this.queryString(filters)}`
      return url
    },
    fetchData(status) {
      this.searchFilters = { ...this.filters, status: status }
      this.statistics[status].loading = true
      this.$http.get(this.searchQuery(`/v1/companies/${this.$activeCompany().data.company.id}/declarations/unfinished-statistics`, this.searchFilters))
        .then(response => {
          this.statistics[status].stat = response.data.data[status]
          this.statistics[status].loading = false
        })
    },
    reFetchData() {
      Object.keys(this.statistics).forEach(key => {
        this.fetchData(key)
      })
      this.$data.companyList = this.$profile().getUserAndAuthorizedCompanies()
    },
    queryString(filters) {
      const filterList = parseFilters(filters)
      return Object.keys(filterList)
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(filterList[key])}`)
        .join('&')
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
