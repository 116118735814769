<template>
  <b-row>
    <b-col v-if="isLoaded">
      <!-- User Info: Input Fields -->
      <validation-observer
        v-if="countryCode"
        ref="editAccountValidation"
      >
        <b-form>
          <b-row>

            <!-- Field: Full Name -->
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                label="Name"
                label-for="full-name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="name"
                  rules="required|min:3|max:255"
                >
                  <b-form-input
                    id="full-name"
                    v-model="userData.name"
                    :state="errors.length > 0 ? false:null"
                    autocomplete="off"
                  />
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Field: Phone -->
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                label="Phone"
                label-for="phone"
              >
                <validation-provider
                  #default="{ errors }"
                  name="phone"
                  rules="required|min:7|max:15"
                >
                  <b-form-input
                    id="phone"
                    v-model="userData.phone"
                    type="phone"
                    :state="errors.length > 0 ? false:null"
                    autocomplete="off"
                  />
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Field: Email -->
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                label="Email"
                label-for="email"
              >
                <b-form-input
                  id="email"
                  v-model="userData.email"
                  type="email"
                  :disabled="true"
                  autocomplete="off"
                />
              </b-form-group>
            </b-col>

          </b-row>
        </b-form>

        <!-- PERMISSION TABLE -->
        <b-card
          no-body
          class="border mt-1"
        >
          <b-card-header class="p-1">
            <b-card-title class="font-medium-2">
              <feather-icon
                icon="LockIcon"
                size="18"
              />
              <span class="align-middle ml-50">Role</span>
            </b-card-title>
          </b-card-header>
          <b-table
            id="roleTable"
            ref="userTable"
            striped
            responsive
            class="mb-0"
            :items="permissions"
            :fields="columns"
          >
            <template #cell(module)="data">
              {{ data.value }}
            </template>
            <template #cell(description)="data">
              {{ data.value }}
            </template>
            <template #cell()="data">
              <b-form-radio
                v-if="data.item.module !== 'Declarant'"
                v-model="selectedRole"
                name="role"
                :value="data.item.module"
              />
              <b-form-checkbox
                v-if="data.item.module === 'Declarant' && isDeclarant"
                v-model="selectedOptionalRole"
                name="optional_role"
                :value="data.item.module"
              />
            </template>
          </b-table>
        </b-card>

        <!-- PERMISSION TABLE -->
        <b-card
          v-if="canAddPermissions"
          no-body
          class="border mt-1"
        >
          <b-card-header class="p-1">
            <b-card-title class="font-medium-2">
              <feather-icon
                icon="LockIcon"
                size="18"
              />
              <span class="align-middle ml-50">Permissions</span>
            </b-card-title>
          </b-card-header>
          <b-form-checkbox-group
            id="rolePermissions"
            v-model="selectedPermissions"
            class="m-1"
            :options="rolePermissions[selectedRole]"
          />
        </b-card>

        <eds-edit
          v-if="countryCode === 'lv'"
          :event-hub="eventHub"
          :country-code="countryCode"
          :user-id="userId"
          :company-id="company.id"
        />
      </validation-observer>

      <!-- Action Buttons -->
      <b-button
        variant="primary"
        class="mb-1 mb-sm-0 mr-0 mr-sm-1"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        @click="validationForm"
      >
        Save Changes
      </b-button>

      <!-- Lock -->
      <b-button
        v-if="userData.status === 'active'"
        variant="warning"
        class="mb-1 mb-sm-0 mr-0 mr-sm-1"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        @click="setStatus('locked')"
      >
        Lock user
      </b-button>

      <!-- Unlock -->
      <b-button
        v-if="userData.status === 'locked'"
        variant="warning"
        class="mb-1 mb-sm-0 mr-0 mr-sm-1"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        @click="setStatus('active')"
      >
        Unlock user
      </b-button>
    </b-col>
    <b-col
      v-if="isLoaded === false"
      class="text-center"
    >
      <b-spinner label="Loading..." />
    </b-col>
  </b-row>
</template>

<script>
import {
  BButton, BRow, BCol, BFormGroup, BFormInput, BForm, BTable, BCard, BCardHeader, BCardTitle, BFormRadio,
  BFormCheckboxGroup, BFormCheckbox, BSpinner,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import getRolesData from '@/views/users/roleData'
import handleError from '@/views/components/errorHandler'
import { ValidationProvider, ValidationObserver } from 'vee-validate/dist/vee-validate.full'
import EdsEdit from '@/views/users/EdsEdit.vue'
import Vue from 'vue'
import { required } from '@validations'

export default {
  components: {
    BSpinner,
    EdsEdit,
    ValidationProvider,
    ValidationObserver,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormRadio,
    BFormCheckbox,
    BFormCheckboxGroup,
  },
  data() {
    return {
      required,
      isLoaded: false,
      userData: {
        id: null,
        email: null,
        name: null,
        phone: null,
        status: null,
        roles: [],
      },
      permissionsData: [],
      selectedRoles: {},
      selectedRole: null,
      selectedOptionalRole: null,
      eventHub: new Vue(),
      rolePermissions: {
        Viewer: [
          'add manifest',
        ],
      },
      selectedPermissions: [],
      columns: [
        { key: 'module' },
        { key: 'description' },
        { key: 'enabled' },
      ],
    }
  },
  computed: {
    canAddPermissions() {
      return this.selectedRole === 'Viewer'
        && (
          this.$permissions().hasRole('Feeport Admin')
            || this.$permissions().hasRole('Admin')
        )
    },
    isDeclarant() {
      return this.$permissions().hasRole('Declarant') || this.$permissions().hasRole('Feeport Admin')
    },
    permissions() {
      return this.permissionsData
    },
    countryCode() {
      return this.$activeCompany().data.company.country.toLowerCase()
    },
    userId() {
      return this.$route.params.userId
    },
    company() {
      return this.$activeCompany().data.company
    },
  },
  created() {
    this.permissionsData = getRolesData().filter(role => {
      if (role.checkAccess === true && !this.$permissions().hasRole('Feeport Admin')) {
        // Only show the role if the user has permission, and exclude Declarant unless the user has it
        if (this.$permissions().hasRole(role.module)) {
          if (role.module !== 'Declarant' || this.$permissions().hasRole('Declarant')) {
            return role
          }
        }
        return false
      }
      return role
    })
    this.fetchUserData()
  },
  methods: {
    setStatus(status) {
      this.$http.post(`/v1/companies/${this.company.id}/user/${this.userId}/status`, {
        status: status,
      })
        .then(() => {
          this.fetchUserData()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'User status updated',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          handleError(error, this.$toast)
        })
    },
    validationForm() {
      this.$refs.editAccountValidation.validate().then(success => {
        if (!success) {
          return
        }
        this.saveChanges()
      })
    },
    saveChanges() {
      // only 1 can be selected but let's not change existing structure
      this.$data.selectedRoles = { [this.selectedRole]: true, [this.selectedOptionalRole]: Boolean(this.selectedOptionalRole) }
      if (!this.canAddPermissions) {
        this.selectedPermissions = []
      }
      if (['lv', 'ee'].includes(this.countryCode)) {
        this.eventHub.$emit('saveCustomsSettings')
      }

      if (parseInt(this.userId, 10) !== this.$profile().data.user.id) {
        // we cannot change our own role
        this.$http.put(`/v1/companies/${this.company.id}/users/${this.userId}`, {
          phone: this.userData.phone,
          name: this.userData.name,
          roles: this.$data.selectedRoles,
          selectedPermissions: this.selectedPermissions,
        })
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'User roles updated',
                icon: 'EditIcon',
                variant: 'success',
              },
            })
          })
          .catch(error => {
            handleError(error, this.$toast)
          })
      }
    },
    fetchUserData() {
      this.isLoaded = false
      this.$http.get(`/v1/companies/${this.company.id}/users/${this.userId}`)
        .then(response => {
          this.userData = response.data.user
          this.$data.permissionsData.forEach(role => {
            if (this.userData.roles.includes(role.module)) {
              if (role.module === 'Declarant') {
                this.selectedOptionalRole = role.module
              } else {
                this.selectedRole = role.module
              }
            }
          })
          if (this.userData.permissions) {
            // filter only permissions related to selected role
            this.selectedPermissions = this.rolePermissions[this.selectedRole]?.filter(permission => this.userData.permissions.includes(permission))
          }
          this.isLoaded = true
          if (this.$refs.userTable) {
            this.$refs.userTable.refresh()
          }
        })
        .catch(error => {
          handleError(error, this.$toast)
        })
    },
  },
  setup(props) {
    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      props.userData.avatar = base64
    })

    return {
      avatarText,

      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
}
</script>
