var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',[_c('b-card',[_c('validation-observer',{ref:"warehousesValidation"},[_c('b-form',[_c('b-row',[_c('b-col',[_c('b-tabs',[_c('b-tab',{attrs:{"active":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [(_vm.tabErrors.details > 0)?_c('b-badge',{staticClass:"mr-1",attrs:{"pill":"","variant":"danger"}},[_vm._v(" "+_vm._s(_vm.tabErrors.details)+" ")]):_vm._e(),_c('feather-icon',{staticClass:"mr-0 mr-sm-50",attrs:{"icon":"InfoIcon","size":"16"}}),_c('span',{staticClass:"d-none d-sm-inline"},[_vm._v("Details")])]},proxy:true}])},[_c('b-row',[_c('b-col',{attrs:{"lg":"6","md":"12"}},[_c('b-form-group',{attrs:{"label":"Name","label-for":"name"}},[_c('validation-provider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","name":"name","state":errors.length > 0 ? false:null,"autocomplete":"off"},model:{value:(_vm.warehouse.name),callback:function ($$v) {_vm.$set(_vm.warehouse, "name", $$v)},expression:"warehouse.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"label":"Code","label-for":"code"}},[_c('validation-provider',{attrs:{"name":"Code","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"code","name":"code","state":errors.length > 0 ? false:null,"autocomplete":"off"},model:{value:(_vm.warehouse.code),callback:function ($$v) {_vm.$set(_vm.warehouse, "code", $$v)},expression:"warehouse.code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"6","md":"12"}},[_c('b-form-group',{attrs:{"label":"Type of location","label-for":"type"}},[_c('validation-provider',{attrs:{"name":"Type of location","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"type","label":"text","options":_vm.typeOptions,"reduce":function (option) { return option.value; }},model:{value:(_vm.warehouse.type),callback:function ($$v) {_vm.$set(_vm.warehouse, "type", $$v)},expression:"warehouse.type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"6","md":"12"}},[_c('b-form-group',{attrs:{"label":"Customs office","label-for":"customs-office"}},[_c('validation-provider',{attrs:{"name":"Customs office","rules":"required|length:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"customs-office","name":"customsOffice","state":errors.length > 0 ? false:null,"autocomplete":"off"},model:{value:(_vm.warehouse.customsOffice),callback:function ($$v) {_vm.$set(_vm.warehouse, "customsOffice", $$v)},expression:"warehouse.customsOffice"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"6","md":"12"}},[_c('b-form-group',{attrs:{"label":"Qualifier of identification","label-for":"qualifier"}},[_c('validation-provider',{attrs:{"name":"Qualifier of identification","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"qualifier","label":"text","options":_vm.qualifierOptions,"reduce":function (option) { return option.value; }},model:{value:(_vm.warehouse.qualifier),callback:function ($$v) {_vm.$set(_vm.warehouse, "qualifier", $$v)},expression:"warehouse.qualifier"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1),_c('b-tab',{scopedSlots:_vm._u([{key:"title",fn:function(){return [(_vm.tabErrors.address > 0)?_c('b-badge',{staticClass:"mr-1",attrs:{"pill":"","variant":"danger"}},[_vm._v(" "+_vm._s(_vm.tabErrors.address)+" ")]):_vm._e(),_c('feather-icon',{staticClass:"mr-0 mr-sm-50",attrs:{"icon":"HomeIcon","size":"16"}}),_c('span',{staticClass:"d-none d-sm-inline"},[_vm._v("Address")])]},proxy:true}])},[_c('b-row',[_c('b-col',{attrs:{"lg":"6","md":"12"}},[_c('b-form-group',{attrs:{"label":"Country","label-for":"country"}},[_c('validation-provider',{attrs:{"name":"Country","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"country","name":"country","state":errors.length > 0 ? false:null,"autocomplete":"off"},model:{value:(_vm.warehouse.country),callback:function ($$v) {_vm.$set(_vm.warehouse, "country", $$v)},expression:"warehouse.country"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"6","md":"12"}},[_c('b-form-group',{attrs:{"label":"Region","label-for":"region"}},[_c('validation-provider',{attrs:{"name":"Region","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"region","name":"region","state":errors.length > 0 ? false:null,"autocomplete":"off"},model:{value:(_vm.warehouse.region),callback:function ($$v) {_vm.$set(_vm.warehouse, "region", $$v)},expression:"warehouse.region"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"6","md":"12"}},[_c('b-form-group',{attrs:{"label":"City","label-for":"city"}},[_c('validation-provider',{attrs:{"name":"City","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"city","name":"city","state":errors.length > 0 ? false:null,"autocomplete":"off"},model:{value:(_vm.warehouse.city),callback:function ($$v) {_vm.$set(_vm.warehouse, "city", $$v)},expression:"warehouse.city"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"6","md":"12"}},[_c('b-form-group',{attrs:{"label":"Postal code","label-for":"postal-code"}},[_c('validation-provider',{attrs:{"name":"Postal code","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"postal-code","name":"postal-code","state":errors.length > 0 ? false:null,"autocomplete":"off"},model:{value:(_vm.warehouse.postalCode),callback:function ($$v) {_vm.$set(_vm.warehouse, "postalCode", $$v)},expression:"warehouse.postalCode"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"6","md":"12"}},[_c('b-form-group',{attrs:{"label":"Street and number","label-for":"streetAndNumber"}},[_c('validation-provider',{attrs:{"name":"Street and number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"streetAndNumber","name":"streetAndNumber","state":errors.length > 0 ? false:null,"autocomplete":"off"},model:{value:(_vm.warehouse.streetAndNumber),callback:function ($$v) {_vm.$set(_vm.warehouse, "streetAndNumber", $$v)},expression:"warehouse.streetAndNumber"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-button',{attrs:{"type":"button","variant":"primary"},on:{"click":_vm.validationForm}},[_vm._v(" Submit ")])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }