import Vue from 'vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'
import moment from 'moment'
import numberFormat from '@/libs/number-format'

Vue.component(FeatherIcon.name, FeatherIcon)

Vue.filter('formatDate', (value, defaultValue) => {
  let m = moment(value, ['DD-MM-YYYY HH:mm:ss', 'YYYY-MM-DD HH:mm:ss'], true)
  // Fallback to generic parsing if not valid
  if (!m.isValid()) {
    m = moment(value) // Use moment's default parsing for other formats
  }
  return m.isValid() ? m.format('DD.MM.YYYY HH:mm') : defaultValue
})
Vue.filter('formatNumber', (value, decimalPlaces) => numberFormat.format(value, decimalPlaces))
Vue.filter('formatNumberWithZero', (value, decimalPlaces) => numberFormat.format(value, decimalPlaces) || 0)
